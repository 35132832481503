<template>
  <div>
    <div class="col-12">
      <c-card title="미리보기" height="470px" :useCollapse="false">
        <template slot="card-button">
          <q-btn-group outline>
            <c-btn label="출력" color="black" icon="print" @btnClicked="getList" />
            <c-btn color="purple" label="크게보기" icon="zoom_in" @btnClicked="openPopup"/>
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <q-img
              src="https://source.unsplash.com/random"
              spinner-color="white"
              style="width:100%;"
            />
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "equipment-maintenance",
  data() {
    return {
      grid: {
        merge: [{ index: 0, colName: "plantName" }],
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            align: "center",
            sortable: true,
          },
          {
            name: "deptNm",
            field: "deptNm",
            label: "LBLDEPT",
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCd",
            field: "equipmentCd",
            label: "설비명(관리번호)",
            align: "center",
            sortable: true,
            type: "link",
          },
          {
            name: "safChkStepCd",
            field: "safChkStepCd",
            label: "LBLPROGRESS",
            align: "center",
            sortable: true,
          },
          {
            name: "safCheckTypeNm",
            field: "safCheckTypeNm",
            label: "정비유형",
            align: "center",
            sortable: true,
          },
          {
            name: "befInspYear",
            field: "befInspYear",
            label: "이전 점검일",
            align: "center",
            sortable: true,
          },
          {
            name: "inspCycle",
            field: "inspCycle",
            label: "점검주기",
            align: "center",
            sortable: true,
          },
          {
            name: "nextInspMon",
            field: "nextInspMon",
            label: "차기검사예정월",
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLaws",
            field: "relatedLaws",
            label: "관련법규",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        safCheckTypeCd: null,
        safChkStepCd: null,
      },
      editable: true,
      checkTypeCdItems: [
        { code: '1', codeName: '예방정비' },
        { code: '2', codeName: '예측정비' },
        { code: '3', codeName: '고장정비' },
      ], // 점검종류
      checkStepCdItems: [
        { code: "1", codeName: "계획수립" },
        { code: "2", codeName: "정비중" },
        { code: "3", codeName: "점검완료" },
      ], // 점검종류
      searchUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      isPreview: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          equipmentCheckSeq: 1,
          plantName: "사업장1",
          deptNm: "안전환경팀",
          equipmentCd: "F3-G938",
          safCheckTypeNm: "예방정비",
          safChkStepCd: 'EI02',
          safChkStepNm: '점검중',
          befInspYear: "2021-01-22",
          inspCycle: "6개월",
          nextInspMon: "2021-07-22",
          relatedLaws: "산업안전보건법",
        },
        {
          equipmentCheckSeq: 2,
          plantName: "사업장2",
          deptNm: "개발자팀",
          equipmentCd: "F3-PV-15",
          safCheckTypeNm: "예측정비",
          safChkStepCd: 'EI02',
          safChkStepNm: '점검중',
          befInspYear: "2020-02-20",
          inspCycle: "1년",
          nextInspMon: "2021-02-20",
          relatedLaws: "산업안전보건법",
        },
      ];
    },
  },
};
</script>
